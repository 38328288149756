<template>
  <div class="content">
    <div class="content__title content__title--party">
      <h1 class="title title--big title--theme">Статистика</h1>
    </div>
    <div class="content__tabs">
      <div class="tabs-list">
        <ul class="tabs-list__body">
          <li class="tabs-list__item">
            <router-link
              :to="{ name: 'stat-all' }"
              exact-active-class="tabs-list__link--active"
              class="tabs-list__link"
            >
              Общая статистика
            </router-link>
          </li>
          <li class="tabs-list__item">
            <router-link
              :to="{ name: 'stat-unloading' }"
              exact-active-class="tabs-list__link--active"
              class="tabs-list__link"
            >
              Выгрузки
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script>
export default {
  name: 'StatisticsLayout',
  data() {
    return {
      client: {},
    }
  },
}
</script>

<style lang="sass">
.fade-enter-active, .fade-leave-active
  transition: opacity 0.5s

.fade-enter, .fade-leave-to
  opacity: 0
</style>
